
import { Http } from "@/http"
import { Id, res_list, req_list } from '@/types/global'

export interface settings{
    id:Id,
    create_time:string
    update_time:string
    put_num:number
    get_num:number
    refund_ratio:number
}


class HttpSettings extends Http{
	get_list = (data:req_list) => {
		return this.get<res_list<settings>>({...data})
	}

    get_info = (id:number) => {
        return this.get<settings>({},`/admin/config/${id}/`)
    }
    edit = ({id,...data}: settings) => {
        return this.patch(data,`/admin/config/${id}/`)
    }
    get_config = ()=>{
        return this.get({},`/admin/config/`)
    }
}

export const api_settings =  new HttpSettings('/admin/config/')
